import React, { useState } from "react";
import style from "./crypto.module.scss";
import BoxData from "../../../Data/Market/Crypto/boxdata";
import TableData from "../../../Data/Market/Crypto/TableData";
import Winners from "../../../Data/Market/Crypto/winners";
const Crypto = () => {
  const [Bdata] = useState(BoxData);
  const [Tdata] = useState(TableData);
  const [Winner] = useState(Winners);
  const [active, setActive] = useState(1);
  return (
    <>
      <div className={style.crypto}>
        <div className={style.Boxsection}>
          {Bdata.map((currValue) => {
            const { id, title, text, btnvalue, imgpath } = currValue;
            return (
              <div className={style.box} key={id}>
                <div className={style.icons}>
                  <img src={imgpath} alt="BitCoin" />
                </div>
                <div className={style.textsection}>
                  <label>{title}</label>
                  <p>{text}</p>
                  <button>{btnvalue}</button>
                </div>
              </div>
            );
          })}
        </div>
        <div className={style.Tablesection}>
          <div className={style.markettable}>
            <div className={style.innermarket}>
              <table>
                <thead>
                  <tr>
                    <td>Assets</td>
                    <td>Last Price</td>
                    <td>24 Hour Change</td>
                    <td>24 Hour High</td>
                    <td>24 Hour low</td>
                    <td>Trade now</td>
                  </tr>
                </thead>
                <tbody>
                  {Tdata.map((Tablevalue) => {
                    const {
                      id,
                      ImgPath,
                      Imgtext1,
                      Imgtext2,
                      lprice,
                      hours,
                      high,
                      low,
                      trade,
                    } = Tablevalue;
                    return (
                      <div className={style.rowdiv} key={id}>
                        <tr>
                          <td>
                            <img src={ImgPath} alt="Images" />
                            {Imgtext1}&nbsp;<span>{Imgtext2}</span>
                          </td>
                          <td>{lprice}</td>
                          <td>{hours}</td>
                          <td>{high}</td>
                          <td>{low}</td>
                          <td>
                            <button>{trade}</button>
                          </td>
                        </tr>
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className={style.Mainwinnertable}>
            <div className={style.innerwinner}>
              <ul>
                <li
                  onClick={() => setActive(1)}
                  className={`${style.navItem} ${
                    active === 1 ? style.active : ""
                  }`}
                >
                  Top Gainers
                </li>
                <li
                  onClick={() => setActive(2)}
                  className={`${style.navItem} ${
                    active === 2 ? style.active : ""
                  }`}
                >
                  Top Losers
                </li>
              </ul>
              {active === 1 && (
                <div className={style.winnertable}>
                  <table>
                    <thead>
                      <tr>
                        <td>Asset</td>
                        <td>Price</td>
                        <td>Change</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Winner.map((win) => {
                        const { id, price, change, asset } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td>{asset}</td>
                            <td>{price}</td>
                            <td>
                              <span>{change}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {active === 2 && (
                <div className={style.winnertable}>
                  <table>
                    <thead>
                      <tr>
                        <td>Asset</td>
                        <td>Price</td>
                        <td>Change</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Winner.map((win) => {
                        const { id, price, change, asset } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td>{asset}</td>
                            <td>{price}</td>
                            <td>
                              <span className={style.clr}>{change}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Crypto;
