import Mask from "../../../assets/images/Mask.svg";
import Silver from "../../../assets/images/silver.svg";
const TableData = [
  {
    id: 1,
    ImgPath: Mask,
    Imgtext1: "XAUU",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 2,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 3,
    ImgPath: Silver,
    Imgtext1: "SILVER",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 4,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 5,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 6,
    ImgPath: Silver,
    Imgtext1: "SILVER",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
];
export default TableData;
