import React from "react";
import style from "./market.module.scss";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import Crypto from "../../assets/images/crypto.svg";
import Stock from "../../assets/images/stock.svg";
import Forex from "../../assets/images/forex.svg";
import Commodities from "../../assets/images/commodities.svg";
import CryptoComponent from "./Crypto/Crypto";
import StockComponent from "./Stocks/Stocks";
import ForexComponent from "./Forex/Forex";
import CommoditiesComponent from "./Commodities/Commodities";
import MintBurnLog from "../../assets/images/MintBurn.svg";
import MintBurn from "../Stake/MintBurn/MintBurn";
const Pathlist = [
  {
    title: "Crypto",
    Icon: Crypto,
    path: "/market/crypto",
  },
  {
    title: "Stocks",
    Icon: Stock,
    path: "/market/stocks",
  },
  {
    title: "Forex",
    Icon: Forex,
    path: "/market/forex",
  },
  {
    title: "Commodities",
    Icon: Commodities,
    path: "/market/commodities",
  },
  {
    title: "Mint & Burn",
    Icon: MintBurnLog,
    path: "/market/mint-burn",
  },
];

const Market = ({
  history,
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "crypto" ? (
            <CryptoComponent />
          ) : id === "stocks" ? (
            <StockComponent />
          ) : id === "forex" ? (
            <ForexComponent />
          ) : id === "commodities" ? (
            <CommoditiesComponent />
          ) : id === "mint-burn" ? (
            <MintBurn />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Market;
