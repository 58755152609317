import Apple from "../../../assets/images/apple.svg";
import Microsoft from "../../../assets/images/Microsoft.svg";
import Gmail from "../../../assets/images/gmail.svg";
import Amazon from "../../../assets/images/Amazon.svg";
import Tesla from "../../../assets/images/Tesla.svg";
const TableData = [
  {
    id: 1,
    ImgPath: Apple,
    Imgtext1: "AAPL",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 2,
    ImgPath: Microsoft,
    Imgtext1: "MUST",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 3,
    ImgPath: Gmail,
    Imgtext1: "Gmail",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 4,
    ImgPath: Amazon,
    Imgtext1: "AMZN",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 5,
    ImgPath: Tesla,
    Imgtext1: "TSLA",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 6,
    ImgPath: Apple,
    Imgtext1: "Apple",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
];
export default TableData;
