const Winners = [
  {
    id: 1,
    asset: "EURUSD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 2,
    asset: "USDCAD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 3,
    asset: "USDJPY",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 4,
    asset: "AUDUSD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 5,
    asset: "GBPUSD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 6,
    asset: "USDCHF",
    price: "$20.11",
    change: "+6.38",
  },
];
export default Winners;
