import React, { useState } from "react";
import ETH from "../../../assets/images/ETH1.svg";
import btc from "../../../assets/images/bitcoinLogo.svg";
import style from "./allowSwap.module.scss";
import Modal from "../popup/popup";
import ModalStyle from "../ModalStyles/ModalStyle";
import DropDown from "./DropDownSwap/dropDown";
import useSwap from "./useAllowSwap";
import FramerRoute from "../../_Common/FramerRoute";
import LoadingSkeleton from "./LoadingSkeleton/loadingSkeleton";
import SwapIcon from "../../../assets/images/swapIcon.svg";
import noDataIcon from "../../../assets/images/file.png";
import { Link } from "react-router-dom";

function AllowSwap() {
  const {
    swapData,
    selectedTo,
    setSelectedTo,
    selectedFrom,
    setSelectedFrom,
    account,
    popupVisible,
    setPopupVisible,
    submitHandler,
    calculateAmount,
    setClickedFrom,
    clickedFrom,
    AmountFrom,
    AmountTo,
    loadingSwap,
    usdValue,
    SingleUsdValue,
    loadingSwapInput,
    errorMessage,
    loadingSwapData,
  } = useSwap();
  return (
    <>
      <Modal visible={popupVisible} onClose={() => setPopupVisible(false)}>
        <ModalStyle />
      </Modal>
      <div className={style.container}>
        <div className={style.Wrapper}>
          <div className={style.heading}>
            ACX Swap is a convenient, fast, and ultra secure way to execute
            instant cross-chain swaps of your favorite coins across all EVM
            blockchains.
          </div>
          <FramerRoute width="90%">
            {!loadingSwapData ? (
              selectedFrom && selectedTo ? (
                <div className={style.SubWrapper}>
                  <div className={style.HeadingWrapper}>
                    <div className={style.subHeadingWrapper}>
                      <img src={selectedFrom?.icon} alt="btc" />
                      <div className={style.Heading}>
                        {selectedTo && selectedFrom.name}
                      </div>
                    </div>

                    <div
                      className={style.NavWrapper}
                      style={{ border: `2px solid ${selectedFrom?.color}` }}
                    >
                      <div className={style.DropDown}>
                        <DropDown
                          padding="13px 8px"
                          grow="100%"
                          selected={selectedFrom}
                          setSelected={setSelectedFrom}
                          options={swapData && swapData}
                          setClickedFrom={setClickedFrom}
                          from={true}
                          imgShow={true}
                          selectedDisable={selectedTo}
                        />
                      </div>

                      <input
                        type="number"
                        placeholder="0.0"
                        onChange={(e) => {
                          calculateAmount(e.target.value, true);
                        }}
                        value={AmountFrom}
                        // disabled={loadingSwapInput}
                      />
                    </div>
                    <span className={style.errorMessage}>
                      {errorMessage && errorMessage}
                    </span>
                  </div>
                  <img className={style.swapImage} src={SwapIcon} />
                  <div className={style.subContainer}>
                    <div className={style.HeadingWrapper2}>
                      <div className={style.subHeadingWrapper2}>
                        <img src={selectedTo?.icon} alt="Eth" />
                        <div className={style.Heading}>
                          {selectedTo && selectedTo.name}
                        </div>
                      </div>
                      <div className={style.NavWrapper2}>
                        <form action="/">
                          <div
                            className={style.NavWrapper}
                            style={{ border: `2px solid ${selectedTo?.color}` }}
                          >
                            <div className={style.DropDown}>
                              <DropDown
                                padding="13px 8px"
                                grow="100%"
                                selected={selectedTo && selectedTo}
                                setSelected={setSelectedTo}
                                options={swapData && swapData}
                                setClickedFrom={setClickedFrom}
                                from={false}
                                imgShow={true}
                                selectedDisable={selectedFrom}
                              />
                            </div>

                            <input
                              type="number"
                              placeholder="0.0"
                              onChange={(e) => {
                                calculateAmount(e.target.value, false);
                              }}
                              value={AmountTo}
                              // disabled={loadingSwapInput}
                            />
                          </div>
                          <span className={style.errorMessage}>
                            {errorMessage && errorMessage}
                          </span>
                          <div className={style.bottomtextsize}>
                            <div className={style.bottomtext}>{}</div>
                            <div className={style.bottomtext2}>
                              {`Total Price in USD : ${
                                AmountFrom && usdValue ? usdValue : 0.0
                              }$`}
                            </div>
                            <div className={style.bottomtext2}>
                              {" "}
                              {`Fee : ${usdValue ? "0.1%" : 0}`}
                            </div>
                            {AmountFrom && SingleUsdValue?.fromName && (
                              <div className={style.bottomtext2}>
                                {`1 ${SingleUsdValue?.fromName} = ${
                                  SingleUsdValue?.synthValue
                                } ${
                                  SingleUsdValue?.toName
                                } ($${SingleUsdValue?.usdValue.toFixed(9)})`}
                              </div>
                            )}
                          </div>
                          <button
                            type="submit"
                            value="Submit"
                            onClick={submitHandler}
                            disabled={account && loadingSwap}
                          >
                            {loadingSwap ? (
                              <div className={style.spinnerWrapper}>
                                <div className={style.spinner}></div>
                              </div>
                            ) : account ? (
                              "Allow to Swap"
                            ) : (
                              "First Connect to wallet"
                            )}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={style.noData}>
                  <img src={noDataIcon} />
                  <span>
                    You need atleast 2 synth Tokens to start swapping . Please
                    mint some wSACx to get synthetic Token in Market Tab from{" "}
                    <Link
                      to="/market/mint-burn"
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      Mint&Burn
                    </Link>
                    .
                  </span>
                </div>
              )
            ) : (
              <LoadingSkeleton />
            )}
          </FramerRoute>
        </div>
      </div>
    </>
  );
}

export default AllowSwap;
