import BigNumber from "bignumber.js";
import { CoinColor, SwapIcons } from "../Components/Swap/AllowSwap/icons";
import {
  ACX_CONTRACT,
  BUSD_CONTRACT,
  SWAP_CONTRACTS,
  SWAP_STAKING_CONTRACT,
  WRAPPED_SWAP_CONTRACT,
} from "../utils/contract";
import { ConvertFromWEI } from "./Coin.helper";
import {
  GetBondContract,
  GetExchangeContract,
  GetGenericSwapContract,
  GetsSACXContract,
  GetSwapStakingContract,
  GetSwapStakingPriceContract,
  GetsWrapSwapContract,
} from "./Contract/contract.helper";

// export const getSwapData = async () => {
//   try {
//     const data = [];
//     for (let address in SWAP_CONTRACTS) {
//       const customToken = await GetGenericSwapContract(SWAP_CONTRACTS[address]);
//       const name = await customToken.methods._name().call();
//       const symbol = await customToken.methods._symbol().call();
//       const decimals = await customToken.methods._decimals().call();
//       data.push({
//         name,
//         symbol,
//         decimals,
//         contractAddress: SWAP_CONTRACTS[address],
//         icon: SwapIcons[symbol],
//         color: CoinColor[symbol],
//       });
//     }
//     return data;
//   } catch (error) {
//     console.log("error", error);
//     throw new Error();
//   }
// };

export const GetTokenCorrespondingValue = async (from, to, amount, account) => {
  try {
    const usdValue = await SynthToUSD(from, amount);
    const feeValue = usdValue * 0.001;
    const usdValueWithFee = usdValue - feeValue;
    const synthValue = await USDToSynth(to, usdValueWithFee);
    return { usdValueWithFee, synthValue };
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const SynthToUSD = async (coinData, amount) => {
  try {
    const customToken = await GetGenericSwapContract(coinData?.contractAddress);
    const dec = Math.pow(10, Number(coinData?.decimals));
    const amountVal = Number(amount) * dec;
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    const res = await customToken.methods.synthToUsd(amountVal2).call();
    const res1 = res / dec;
    return res1;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const USDToSynth = async (coinData, amount) => {
  try {
    const customToken = await GetGenericSwapContract(coinData?.contractAddress);
    const dec = Math.pow(10, Number(coinData?.decimals));
    const amountVal = Number(amount) * dec;
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    const res = await customToken.methods.usdToSynth(amountVal2).call();
    const res1 = res / dec;
    return res1;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

export const SwapToken = async (from, to, account, amount) => {
  try {
    const dec = Math.pow(10, Number(from?.decimals));
    const amountVal = Number(amount) * dec;
    const customToken = await GetExchangeContract();
    const res = await customToken.methods
      .convertSynths(
        from?.contractAddress,
        to?.contractAddress,
        account,
        amountVal
      )
      .send({ from: account });
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

export const GetSACXTowSACX = async (amount, account) => {
  try {
    const customToken = await GetsWrapSwapContract();
    const dec = await customToken.methods.decimals().call();
    console.log("decimals", dec);
    const decPow = Math.pow(10, Number(dec));
    const amountVal = Number(amount) * decPow;
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    const res = await customToken.methods.SACXTowSACX(amountVal2).call();
    const res1 = res / decPow;
    return res1;
    console.log("amountVal in SACX -> Wrapped", amount, ":", amountVal);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const GetwSACXToSACX = async (amount, account) => {
  try {
    const customToken = await GetsWrapSwapContract();
    const dec = await customToken.methods.decimals().call();
    const decPow = Math.pow(10, Number(dec));
    const amountVal = Number(amount) * decPow;
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    const res = await customToken.methods.wSACXToSACX(amountVal2).call();
    const res1 = res / decPow;
    return res1;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const SubmitWrapSwap = async (selected, amount, account) => {
  try {
    const customToken = await GetsWrapSwapContract();
    const dec = await customToken.methods.decimals().call();
    const decPow = Math.pow(10, Number(dec));
    const amountVal = Number(amount) * decPow;
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    if (selected === "SACX") {
      const res = await customToken.methods
        .wrap(amountVal2)
        .send({ from: account });
    } else if (selected === "wSACX") {
      const res = await customToken.methods
        .unwrap(amountVal2)
        .send({ from: account });
    }
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const CheckApprovedUnStaking = async (account) => {
  const customToken = await GetsSACXContract();
  const approved = await customToken.methods
    .allowance(account, WRAPPED_SWAP_CONTRACT)
    .call();
  return approved;
};
export const SendApprovedUnStaking = async (account) => {
  const customToken = await GetsSACXContract();
  const addApproved = await customToken.methods
    .approve(WRAPPED_SWAP_CONTRACT, "100000000000000000000000")
    .send({ from: account });
  return addApproved;
};
export const getWrappedSACXTokenBalance = async (account) => {
  const customToken = await GetsWrapSwapContract();
  const tokenAmountETH = await customToken.methods.balanceOf(account).call();
  const tokenValue = tokenAmountETH / 1e9;
  return tokenValue;
};
export const getSACXTokenBalance = async (account) => {
  const customToken = await GetsSACXContract();
  const tokenAmountETH = await customToken.methods.balanceOf(account).call();
  const tokenValue = tokenAmountETH / 1e9;
  return tokenValue;
};

export const SwapAssetsList = async (account) => {
  try {
    const customTokenBond = await GetSwapStakingContract();
    const swapCount = await customTokenBond.methods.synthCount().call();
    let swapList = [];
    if (swapCount) {
      for (let i = 0; i < swapCount; i++) {
        const bondData = await customTokenBond.methods.synthAddresses(i).call();
        if (bondData) {
          const customToken = await GetGenericSwapContract(bondData);
          let name = await customToken.methods._name().call();
          let symbol = await customToken.methods._symbol().call();
          const decimals = await customToken.methods.decimals().call();
          const balanceOf = await customToken.methods.balanceOf(account).call();
          const chainlinkPrice = await customToken.methods
            .chainlinkPrice()
            .call();
          let balance = null;
          let chainLinkPrice = null;
          if (decimals) {
            const decPow = Math.pow(10, Number(decimals));
            balance = Number(balanceOf) / decPow;
            chainLinkPrice = Number(chainlinkPrice) / decPow;
          }
          if (decimals) {
            swapList.push({
              name,
              symbol,
              decimals,
              contractAddress: bondData,
              icon: SwapIcons[symbol],
              color: CoinColor[symbol],
              balance,
              chainLinkPrice,
              isActive: balance ? true : false,
            });
          }
        }
      }
    }
    return swapList;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const getSwapData = async (account) => {
  try {
    const SwapList = await SwapAssetsList(account);
    const data = SwapList.filter((swap) => swap.isActive);
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const wSACXtoSynth = async (value, swapSelectedData, account) => {
  try {
    const customToken = await GetGenericSwapContract(
      swapSelectedData?.contractAddress
    );
    const decPow = Math.pow(10, Number(swapSelectedData?.decimals));
    const amountVal = Number(value) * decPow;
    const totalSynthPriceWei = await customToken.methods
      .synthToUsd(amountVal)
      .call();
    const totalSynthPrice = Number(totalSynthPriceWei) / decPow;
    const collatralPrice = (+totalSynthPriceWei * 750) / 100;
    // const collatralPriceWei = Number(collatralPrice) * decPow;
    const maxAmountRound = Math.ceil(Number(collatralPrice));
    const amountRound = BigNumber(maxAmountRound).toFixed();
    const customTokenSwapStaking = await GetSwapStakingContract();
    const res = await customTokenSwapStaking.methods
      .SacxToWsacx(amountRound)
      .call();
    const synthToWsacx = Number(res) / decPow;
    return synthToWsacx;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

export const totalStakedCollateral = async (account) => {
  try {
    const customTokenSwapStaking = await GetSwapStakingContract();
    const res = await customTokenSwapStaking.methods.StakedCollateral().call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const maxMintAmount = async (swapSelectedData, amount) => {
  try {
    const decPow = Math.pow(10, Number(swapSelectedData?.decimals));
    const amountVal = Number(amount) * decPow;
    const customTokenWrapped = await GetsWrapSwapContract();
    const resWsacxToUsd = await customTokenWrapped.methods
      .wSACXToSACX(amountVal)
      .call();
    const customTokenStaking = await GetSwapStakingPriceContract();
    const resSacx = await customTokenStaking.methods
      .AcxToUsd(resWsacxToUsd)
      .call();
    const customToken = await GetGenericSwapContract(
      swapSelectedData?.contractAddress
    );
    const amountGWEI = ConvertFromWEI(resSacx.toString());
    // const amountGWEI = ConvertFromWEI(resSacx.toString());
    const collatralPrice = +amountGWEI / 7.5;
    const amountVal2 = BigNumber(collatralPrice).toFixed(0);
    const resUsdToSynth = await customToken.methods
      .usdToSynth(amountVal2)
      .call();
    const res = Number(resUsdToSynth) / decPow;
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const CheckApprovedMint = async (account) => {
  const customToken = await GetsWrapSwapContract();
  const approved = await customToken.methods
    .allowance(account, SWAP_STAKING_CONTRACT)
    .call();
  return approved;
};
export const SendApprovedMint = async (account) => {
  const customToken = await GetsWrapSwapContract();
  const addApproved = await customToken.methods
    .approve(SWAP_STAKING_CONTRACT, "100000000000000000000000")
    .send({ from: account });
  return addApproved;
};
export const mintSwapToken = async (swapSelectedData, amount, account) => {
  try {
    const decPow = Math.pow(10, Number(swapSelectedData?.decimals));
    const amountVal = Number(amount) * decPow;
    const customToken = await GetSwapStakingContract();
    const res = customToken.methods
      .StakeSacx(swapSelectedData?.contractAddress, amountVal)
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const burnSwapToken = async (swapSelectedData, amount, account) => {
  try {
    const decPow = Math.pow(10, Number(swapSelectedData?.decimals));
    const amountVal = Number(amount) * decPow;
    const customToken = await GetSwapStakingContract();
    const res = customToken.methods
      .UnstakeSacx(swapSelectedData?.contractAddress, amountVal)
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
