const Winners = [
  {
    id: 1,
    asset: "XAUUSD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 2,
    asset: "GoldD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 3,
    asset: "GC2!D",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 4,
    asset: "GOLDSILVER",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 5,
    asset: "GoldD",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 6,
    asset: "XAUUSD",
    price: "$20.11",
    change: "+6.38",
  },
];
export default Winners;
