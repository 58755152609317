const TableData = [
  {
    id: 1,

    Imgtext1: "EURUSD",
    lprice: "U.S. Dollar Index",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 2,

    Imgtext1: "USDCAD",
    lprice: "Euro Fx/U.S. Dollar",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 3,

    Imgtext1: "USDJPY",
    lprice: "U.S. Dollar Index",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 4,
    Imgtext1: "USDCHF",
    lprice: "Euro Fx/U.S. Dollar",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 5,

    Imgtext1: "AUDUSD",
    lprice: "U.S. Dollar Index",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 6,
    Imgtext1: "GBPUSD",
    lprice: "Euro Fx/U.S. Dollar",
    hours: "1,998.00",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
];
export default TableData;
