const Winners = [
  {
    id: 1,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 2,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 3,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 4,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 5,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
  {
    id: 6,
    asset: "LNKa",
    price: "$20.11",
    change: "+6.38",
  },
];
export default Winners;
