import Eurosd from "../../../assets/images/eurosd.svg";
import Usdcad from "../../../assets/images/usdcad.svg";
import Usdpy from "../../../assets/images/usdjpy.svg";
import Usdchf from "../../../assets/images/usdchf.svg";
const BoxData = [
  {
    id: 1,

    text: "56868.6543",
    btnvalue: "6.36 %",
    imgpath: Eurosd,
  },
  {
    id: 2,

    text: "208.33",
    btnvalue: "6.36 %",
    imgpath: Usdcad,
  },
  {
    id: 3,

    text: "3068.6543",
    btnvalue: "6.36 %",
    imgpath: Usdpy,
  },
  {
    id: 4,
    text: "568.65",
    btnvalue: "6.36 %",
    imgpath: Usdchf,
  },
];
export default BoxData;
