import Mask from "../../../assets/images/Mask.svg";
import Silver from "../../../assets/images/silver.svg";

const BoxData = [
  {
    id: 1,
    title: "XAUUSD",
    text: "56868.6543",
    btnvalue: "6.36 %",
    imgpath: Mask,
  },
  {
    id: 2,
    title: "GoldD",
    text: "208.33",
    btnvalue: "6.36 %",
    imgpath: Mask,
  },
  {
    id: 3,
    title: "GC2!D",
    text: "56868.6543",
    btnvalue: "6.36 %",
    imgpath: Mask,
  },
  {
    id: 4,
    title: "GOLDSILVER",
    text: "568.65",
    btnvalue: "6.36 %",
    imgpath: Silver,
  },
];
export default BoxData;
