import Bitcoin from "../../../assets/images/bitcoin.svg";
import litecoin from "../../../assets/images/litecoin.svg";
import Ethrem from "../../../assets/images/ethrm.svg";
import Bitcoing from "../../../assets/images/bitcoingreen.svg";
const TableData = [
  {
    id: 1,
    ImgPath: Bitcoin,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 2,
    ImgPath: litecoin,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 3,
    ImgPath: Ethrem,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 4,
    ImgPath: Bitcoing,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 5,
    ImgPath: Ethrem,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
  {
    id: 6,
    ImgPath: litecoin,
    Imgtext1: "BTCa",
    Imgtext2: "Bitcoin",
    lprice: "$56868.6453",
    hours: "0%",
    high: "$56868.6453",
    low: "$56868.6453",
    trade: "Trade",
  },
];
export default TableData;
