import Apple from "../../../assets/images/apple.svg";
import Microsoft from "../../../assets/images/Microsoft.svg";
import Gmail from "../../../assets/images/gmail.svg";
import Amazon from "../../../assets/images/Amazon.svg";
const BoxData = [
  {
    id: 1,
    title: "AAPL",
    text: "56868.6543",
    btnvalue: "6.36 %",
    imgpath: Apple,
  },
  {
    id: 2,
    title: "MSFT",
    text: "208.33",
    btnvalue: "6.36 %",
    imgpath: Microsoft,
  },
  {
    id: 3,
    title: "Google",
    text: "3068.6543",
    btnvalue: "6.36 %",
    imgpath: Gmail,
  },
  {
    id: 4,
    title: "AMZN",
    text: "568.65",
    btnvalue: "6.36 %",
    imgpath: Amazon,
  },
];
export default BoxData;
