import sDOGE from "../../../assets/images/SDOGE.svg"
import sADA from "../../../assets/images/sADA.svg"
import sMATIC from "../../../assets/images/sMATIC.svg"
import sXRP from "../../../assets/images/sXRP.svg"
import sATOM from "../../../assets/images/sATOM.svg"

export const SwapIcons = {
    sDOGE,
    sADA,
    sMATIC,
    sXRP,
    sATOM
}
export const CoinColor = {
    sDOGE:"#dec270",
    sADA:"#0033AD",
    sMATIC:"#8247E5",
    sXRP:"#23292f",
    sATOM:"#2e3148"
}