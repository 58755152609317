import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwapAssetsList } from "../../../Services/swap.helper";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import { setSwapToken } from "../../../store/reducers/accountReducer";
import { NetworkTestChainId } from "../../../utils/contract";
import { ImportGenericBondToken } from "../../../Services/WalletConnection/ImportCustomToken";
import { setSelectedSwapData } from "../../../store/reducers/swapReducer";

const useMintBurn = () => {
  const dispatch = useDispatch();
  const swapToken = useSelector((state) => state?.accountsReducer?.swapToken);

  const { account, chainId } = useActiveWeb3React();
  const [active, setActive] = useState(true);
  const [loadingSwapData, setLoadingSwapData] = useState(false);
  const [swapData, setSwapData] = useState([]);
  const [swapSelectedData, setSwapSelectedData] = useState();

  const selectSingleHandler = () => {
    setActive(false);
  };
  const checkSwapToken = (e, item) => {
    console.log("item", item);
    e.preventDefault();
    const check = swapToken?.some(
      (add) =>
        add.swapAddress === item?.contractAddress &&
        add.accountAddress === account
    );
    if (!swapToken?.length || !check) {
      if (chainId === NetworkTestChainId) {
        dispatch(
          setSwapToken({
            swapAddress: item?.contractAddress,
            accountAddress: account,
          })
        );
        ImportGenericBondToken(
          item?.contractAddress,
          item?.symbol,
          item?.decimals
        );
      }
    } else {
      setSwapSelectedData(item);
      dispatch(setSelectedSwapData(item));
      selectSingleHandler();
    }
  };
  useEffect(() => {
    GetSwapList();
  }, [account, chainId]);
  const GetSwapList = async () => {
    try {
      setLoadingSwapData(true);
      const res = await SwapAssetsList(account);
      setSwapData(res);
      setLoadingSwapData(false);
      return res;
    } catch (error) {
      setLoadingSwapData(false);
      console.log("error", error);
      throw new Error();
    }
  };

  return {
    active,
    selectSingleHandler,
    setActive,
    loadingSwapData,
    swapData,
    checkSwapToken,
    swapSelectedData,
    account,
    GetSwapList,
    setSwapSelectedData,
    // updateSelectedData,
  };
};

export default useMintBurn;
