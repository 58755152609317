import Bitcoin from "../../../assets/images/bitcoin.svg";
import litecoin from "../../../assets/images/litecoin.svg";
import Ethrem from "../../../assets/images/ethrm.svg";
import Bitcoing from "../../../assets/images/bitcoingreen.svg";
const BoxData = [
  {
    id: 1,
    title: "BTCa/USDa",
    text: "56868.6543",
    btnvalue: "6.36 %",
    imgpath: Bitcoin,
  },
  {
    id: 2,
    title: "LTCa/USDa",
    text: "208.33",
    btnvalue: "6.36 %",
    imgpath: litecoin,
  },
  {
    id: 3,
    title: "ETHa/USDa",
    text: "3068.6543",
    btnvalue: "6.36 %",
    imgpath: Ethrem,
  },
  {
    id: 4,
    title: "BCHa/USDa",
    text: "568.65",
    btnvalue: "6.36 %",
    imgpath: Bitcoing,
  },
];
export default BoxData;
